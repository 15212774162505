<template>
  <div class="consent-management-main">
    <main class="dg-main">
      <DgPanel>
        <h1>{{ $t(`${translationScope}.heading`) }}</h1>
        <p>{{ $t(`${translationScope}.info`) }}</p>
        <i18n :path="`${translationScope}.call_to_action`" tag="p" v-if="!isLoading">
          <a :href="preferenceManagementURL">{{ $t(`${translationScope}.click_here`) }}</a>
        </i18n>
      </DgPanel>
    </main>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { Structural } from "@/dgui-customer-components/";

export default {
  name: "PreferenceManagementLayout",
  components: {
    DgPanel: Structural.DgPanel,
  },
  data() {
    return {
      isLoading: true,
      translationScope: "dg_suite.preference_management.overview",
    };
  },
  computed: {
    ...mapState({
      preferenceManagementAppId: state => state.user.companyData.preference_management_app_id,
      dataCenter: state => state.user.companyData.preference_management_datacenter,
    }),
    ...mapGetters(["serverConfig"]),
    baseURL() {
      return (
        this.serverConfig.platforms.preference_management[this.dataCenter] || "https://dataguard.sandbox.consentric.io/"
      );
    },
    preferenceManagementURL() {
      return `${this.baseURL}?appId=${this.preferenceManagementAppId}`;
    },
  },
  created() {
    this.isLoading = false;
    setTimeout(() => {
      window.location.href = this.preferenceManagementURL;
    }, 5000);
  },
};
</script>
